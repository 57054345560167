@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "lato";
    src: url("fonts/Lato-Bold.ttf") format("truetype");
    src: url("fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "montserrat";
    src: url("fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

h1, h2, h3, h4{
    font-family: "lato";
}
body,p{
    font-family: "montserrat" !important;
}

.btn-primary, .btn-success{
    --bs-btn-padding-y: 0.575rem !important;
    --bs-btn-font-size: 1.1rem !important;
}
.form-control{
    font-size: 0.875rem !important;
    line-height: 1.7 !important;
}
h2{
    font-size: calc(1.1rem + .8vw) !important;
}
@keyframes spinner-fade{
    from{opacity: 1}
    to{
      opacity: 0.01;
    }
  }

.anim-card{
    animation: pop 150ms ;
}
.anim-left{
    animation: fadeLeft 150ms ;
    transform-origin: center right;
}

@keyframes fadeLeft {
    from{opacity: 0; transform: scaleX(0.8);}
    to{opacity: 1; transform: scaleX(1);}
}

@keyframes pop {
    from{opacity: 0; transform: scaleY(0.9);}
    to{opacity: 1; transform: scaleY(1);}
}

.leaderboard > div:nth-child(1){
    border-color: rgb(202, 138, 4) !important;
    border-bottom-width: 3px;
    /* border-left-width: 3px; */
    /* transform: scale(1.06); */
}

.leaderboard > div:nth-child(2){
    border-color: #D7D7D7 !important;
    border-bottom-width: 3px;
    /* border-left-width: 3px; */
    /* transform: scale(1.03); */


}
.leaderboard > div:nth-child(3){
    border-color: #AD8A56 !important;
    border-bottom-width: 3px;
    /* border-left-width: 2px; */

}

.shadowBox{
    border: 1px solid #D9D9D9AA;
    border-radius: 3px;
    box-shadow: 3px 3px 0px #d9d9d9aa;
}