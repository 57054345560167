.fade-up{
    opacity: 0;
    transform: translateY(10px);
    animation: fadeUp 350ms ease-out forwards;
}
.fade-down{
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeDown 350ms ease-out forwards;
}


@keyframes fadeUp {
    to{opacity: 1; transform: translateY(0);}
}
@keyframes fadeDown {
    to{opacity: 1; transform: translateY(0);}
}